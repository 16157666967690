import React from "react";

function SessionView(props) {
  const {
    fancy = [],
    userRole,
    fancyStatusChange,
    fetchFancyPosition,
    onClickPositionModal,
  } = props;
  return (
    <div className="row session-resp-tbl table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th
              colSpan="10"
              className="bet-place-tbl-th market_type_row session-th-strip align-left"
            >
              <div className="lock_toggle_box">
                {+userRole?.role === 1 || +userRole?.role === 8 ? (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={fancy?.data.some((item) => !item?.bet_lock)}
                      onChange={(e) =>
                        fancyStatusChange(
                          e,
                          +fancy?.session_type === 1
                            ? ["1", "2", "3", "4"]
                            : [fancy?.session_type],
                          fancy?.data.some((item) => !item?.bet_lock) ? 0 : 1
                        )
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                ) : (
                  <></>
                )}
                <span>PENDING SESSIONS</span>
              </div>
            </th>
          </tr>
          <tr>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              {" "}
              {fancy?.session?.name ? fancy?.session?.name.toUpperCase() : ""}
            </th>
            <th className="bet-odds-type bet-place-tbl-th">No</th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              RATE
            </th>
            <th className="bet-odds-type bet-place-tbl-th">Yes</th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              RATE
            </th>
            <th className="bet-odds-type bet-place-tbl-th">Position NO</th>
            <th className="bet-odds-type bet-place-tbl-th">Position Yes</th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {fancy?.data.map((session, index) => (
            <tr key={index}>
              <td
                data-column="session_title"
                className="bet-place-tbl-td "
                style={{ textAlign: "left" }}
              >
                <div className="lock_toggle_box">
                  {
                    // role && role == "1"
                    userRole?.role == 1 || userRole?.role == 8 ? (
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={!session.bet_lock}
                          onChange={(e) =>
                            fancyStatusChange(
                              e,
                              session.session_id,
                              session.bet_lock
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    ) : null
                  }{" "}
                  <span>
                    {session?.runner_name
                      ? session.runner_name.slice(0, 30)
                      : ""}
                  </span>
                </div>
              </td>

              <React.Fragment>
                {session.game_status && session.game_status != "" ? (
                  <>
                    {/* <td
                      id="session_status"
                      colSpan={4}
                      data-column="session_title"
                      className="SessionTable bet-place-tbl-td cursor-pointer close-status"
                      style={{
                        // width: "100%",
                        right: 0,
                        left: 0,
                        textAlign: "center",
                        color: 'red',
                        background: '#e4e4e4'
                      }}
                    >
                      {session.game_status}
                    </td> */}
                    <td
                      data-column="no_runs"
                      className="SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer close-status"
                      data-id="NO"
                      match-id="405125"
                      session-run-id="2302722"
                      session-id="63411"
                      id="63411_no"
                    >

                    </td>
                    <td
                      data-column="no_rate"
                      id="63411_no_rate"
                      className="no_rate bet-place-tbl-td bet-val-cent close-status"
                    >

                    </td>
                    <td
                      data-column="yes_runs"
                      className="SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer close-status"
                      data-id="YES"
                      match-id="405125"
                      session-run-id="2302722"
                      session-id="63411"
                      id="63411_yes"
                    >

                    </td>
                    <td
                      data-column="yes_rate"
                      className="yes_rate bet-place-tbl-td bet-val-cent close-status"
                      id="63411_yes_rate"
                    >

                    </td>
                  </>
                ) :
                  <React.Fragment>
                    <td
                      data-column="no_runs"
                      className="SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer close-status"
                      data-id="NO"
                      match-id="405125"
                      session-run-id="2302722"
                      session-id="63411"
                      id="63411_no"
                    >
                      {session.lay_price1}
                    </td>
                    <td
                      data-column="no_rate"
                      id="63411_no_rate"
                      className="no_rate bet-place-tbl-td bet-val-cent close-status"
                    >
                      {session.lay_size1}
                    </td>
                    <td
                      data-column="yes_runs"
                      className="SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer close-status"
                      data-id="YES"
                      match-id="405125"
                      session-run-id="2302722"
                      session-id="63411"
                      id="63411_yes"
                    >
                      {session.back_price1}
                    </td>
                    <td
                      data-column="yes_rate"
                      className="yes_rate bet-place-tbl-td bet-val-cent close-status"
                      id="63411_yes_rate"
                    >
                      {session.back_size1}
                    </td>

                  </React.Fragment>}
              </React.Fragment>
              <td
                className="bet-place-tbl-td bet-val-cent"
                id="session_no_runns_63411"
                style={{
                  color:
                    fetchFancyPosition(session.session_id, "no") >= 0
                      ? "#228C22"
                      : "#FF0000",
                }}
              >
                {fetchFancyPosition(session.session_id, "no")}
              </td>
              <td
                className="bet-place-tbl-td bet-val-cent"
                id="session_yes_runns_63411"
                style={{
                  color:
                    fetchFancyPosition(session.session_id, "yes") >= 0
                      ? "#228C22"
                      : "#FF0000",
                }}
              >
                {fetchFancyPosition(session.session_id, "yes")}
              </td>
              <td className="bet-place-tbl-td">
                <a
                  className="btn btn-info"
                  data-method="get"
                  data-remote="true"
                  onClick={() => onClickPositionModal(session)}
                >
                  POSITION
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SessionView;
