import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link } from "react-router-dom";
import { listRequest } from "_actions/subcompany/subcompany.actions";
import Pagination from "components/common/Pagination";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";

import {
  betLockUnLock,
  lockedUserList,
  casinolockUnlock,
  activeInactiveUsers,
  list,
} from "../../_services/master.services";
import locked from "../../images/locked.png";
import unlocked from "../../images/unlocked.png";
import { blockedUser } from "_services/settlements.services";

class List extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    subCompanyList: [],
    totalRecords: 0,
    role: [2],
    keyword: "",
    currentlyPage: 1,
    perPage: 10,
    pageCount: 0,
    records_all: "",
  };

  debouncedLog = debounce((text) => this.fetchSubCompanyData(text), 500);

  componentDidMount() {
    this.fetchSubCompanyData(this.state.keyword);
  }

  fetchSubCompanyData = (searchData = "") => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentlyPage,
      items_perpage: this.state.perPage,
      keyword: searchData,
      role: this.state.role,
    };
    if (this.props.isBlocked) {
      this.getBlockedUserList(sendObj)
    } else {
      this.props.dispatch(
        listRequest(sendObj, (res) => {
          if (res && res.data && res.data.total_records) {
            this.setState({
              subCompanyList: res.data.userdownlist,
              totalRecords: res.data.total_records,
            });
            this.setState({
              records_all: Math.ceil(res.data.total_records / this.state.perPage),
            });
          } else {
            this.setState({
              subCompanyList: [],
              totalRecords: 0,
            });
          }
        })
      );
    }
  };

  getBlockedUserList = (sendData) => {
    blockedUser(sendData)
      .then((res) => {
        if (res && res.data && res?.data?.data) {
          this.setState({
            subCompanyList: res?.data?.data?.userdownlist,
            totalRecords: res?.data?.data?.total_records,
          });
          this.setState({
            records_all: Math.ceil(res?.data?.data?.total_records / this.state.perPage),
          });
        } else {
          this.setState({
            subCompanyList: [],
            totalRecords: 0,
          });
        }
      })
      .catch((error) => {
        this.setState({
          subCompanyList: [],
          totalRecords: 0,
        })
      });
  }

  getRecordDetails = () => {
    const { currentlyPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentlyPage === 1
        ? 1
        : this.state.currentlyPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentlyPage === 1
          ? this.state.perPage
          : this.state.currentlyPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  statusChange = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentlyPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({
                subCompanyList: response.data.data.userdownlist,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          let sendObj = {
            user_guid: user.user_guid,
            current_page: this.state.currentlyPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: this.state.role,
          };

          list(sendObj)
            .then((response) => {
              this.setState({
                subCompanyList: response.data.data.userdownlist,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              let sendObj = {
                user_guid: user.user_guid,
                current_page: this.state.currentlyPage,
                items_perpage: this.state.perPage,
                keyword: this.state.keyword,
                role: this.state.role,
              };

              list(sendObj)
                .then((response) => {
                  this.setState({
                    subCompanyList: response.data.data.userdownlist,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentlyPage: selectedPage + 1 }, () => {
      this.fetchSubCompanyData(this.state.keyword);
    });
  };

  render() {
    const { subCompanyList, totalRecords, perPage, currentlyPage } = this.state;
    const { isBlocked, title } = this.props;
    return (
      <>
        <InnerPageHeader
          pageTitle={title ? title : "Sub Company List"}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            // { title: "Sub Company", url: "/subcompany" },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Subcompanies</h5>
                  {!isBlocked &&
                    <div className="ibox-tools">
                      <a
                        href="/manage-subcompany/add"
                        className="btn btn-primary btn-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/manage-subcompany/add");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Create Sub Company
                      </a>
                    </div>}
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No </th>
                            <th>User Name</th>
                            <th>Name</th>
                            <th>Fix Limit</th>
                            <th>My Share</th>
                            <th>Max Share</th>
                            <th>Casino Share</th>
                            {/* <th>User Active/Inactive</th>
                            <th>Bet Lock/Unlock</th>
                            <th>Casino Lock/UnLock</th> */}
                            <th>Casino Lock/Hide</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subCompanyList && subCompanyList.length ? (
                            subCompanyList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                {/* { console.log(user, "user")} */}
                                <td>{(index + 1) + ((currentlyPage - 1) * perPage)}</td>
                                <td>
                                  <a
                                    // href="/"
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   this.props.history.push(
                                    //     `/userview/master/${user.user_guid}`
                                    //   );
                                    // }}
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/userview/manage-subcompany/${user.user_guid}/${user.username}`
                                      );
                                    }}
                                  >
                                    {user.username}
                                  </a>
                                </td>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td className="center">{user.fixed_limit}</td>
                                <td className="center">
                                  {user.company_share
                                    ? 100 - parseInt(user.company_share)
                                    : 100}
                                </td>
                                <td className="center">
                                  {user.company_share ? user.company_share : 0}
                                </td>
                                {/* 
                                <td className="text-center">
                                  <a
                                    onClick={(e) =>
                                      this.statusChange(e, user, index)
                                    }
                                  >
                                    <img
                                      src={
                                        user.status == "1" ? unlocked : locked
                                      }
                                    />
                                  </a>
                                </td>

                                <td className="text-center">
                                  <a
                                    onClick={(e) =>
                                      this.lockUnlockBet(e, user, index)
                                    }
                                  >
                                    <img
                                      src={
                                        user.lock_betting == "0"
                                          ? unlocked
                                          : locked
                                      }
                                    />
                                  </a>
                                </td>

                                <td className="text-center">
                                  <a
                                    onClick={(e) =>
                                      this.lockUnlockCasino(e, user, index)
                                    }
                                  >
                                    <img
                                      src={
                                        user.lock_casino_bet == "0"
                                          ? unlocked
                                          : locked
                                      }
                                    />
                                  </a>
                                </td> */}
                                <td className="center">{user.game_share}</td>
                                <td className="center">
                                  <Link
                                    className="btn btn-primary btn-sm"
                                    to={`/casino-setting/${user?.user_id}`}
                                    state={{ selectedData: user }}
                                  > Casino Lock/Hide
                                  </Link>
                                </td>
                                <td className="d-flex">
                                  <a
                                    className="btn btn-white btn-sm"
                                    href={`/manage-subcompany/edit/${user?.user_guid}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push({
                                        pathname: `/manage-subcompany/edit/${user?.user_guid}`,
                                        state: { selectedData: user, lastPath: this.props.location?.pathname, isBlocked },
                                      });
                                    }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </a>

                                  &nbsp;&nbsp; &nbsp;&nbsp;

                                  {!isBlocked && <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/edit_password/${user.user_guid}`
                                      );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-w-m btn-primary"
                                    >
                                      Change Password
                                    </button>
                                  </a>}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>

                        <tfoot>
                          <tr>
                            <th>Total</th>
                            <th> - </th>
                            <th> - </th>
                            <th>
                              {" "}
                              {Math.round(
                                subCompanyList?.reduce(
                                  (a, v) => (a = +a + +v.fixed_limit),
                                  0
                                ) * 100
                              ) / 100}{" "}
                            </th>
                            <th> - </th>
                            <th>
                              {" "}
                              {Math.round(
                                subCompanyList?.reduce(
                                  (a, v) => (a = +a + +v.company_share),
                                  0
                                ) * 100
                              ) / 100}{" "}
                            </th>
                          </tr>
                        </tfoot>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.records_all}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
