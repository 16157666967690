import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const staffUserList = (data) => {
    return performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}users/get_staff_list`,
      data
    );
  };

export const staffUserCreate = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/create_staff`,
    data
  );
};

export const activeDeactiveStaff = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/active_inactive_staff`,
    data
  );
};

export const deleteStaff = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/delete_staff`,
    data
  );
};