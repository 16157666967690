import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { casinoResult } from "_services/casino.services";
const imageURL = "https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/cards";

export default function GameListComp(props) {
  const { history } = props;
  const location = useLocation();
  const { id } = useParams();
  const { matchData } = location.state;

  const [casinoResultList, setCasinoResultList] = useState(null);

  useEffect(() => {
    getCasinoResult();
  }, []);

  const getCardImage = (card) => {
    return `${imageURL}/${card}.png`;
  };

  const getCasinoResult = async () => {
    const data = {
      casino_round_id: matchData.casino_round_id,
    };
    try {
      let res = await casinoResult(data);
      if (res?.data) {
        setCasinoResultList(res?.data);
      }
    } catch (error) {}
  };

  const organizeCards = (players, cardResult) => {
    const playerObj = players ? players : null;

    cardResult.forEach((element, index) => {
      const keys = Object.keys(playerObj);
      const minIndex = Math.min(...keys.map((key) => playerObj[key].length));
      const minKey = keys.find((key) => playerObj[key].length === minIndex);

      if(!!element && element?.length > 2) playerObj[minKey].push(element);
    });

    return playerObj;
  };

  const renderCardContainer = (title, cards) => (
    <div className="card-container row1">
      <b>{title}:</b>{" "}
      {cards
        .filter((p) => p !== "1")
        .map((card) => (
          <img key={card} src={getCardImage(card)} alt="card" />
        ))}
    </div>
  );

  const ReusableCardComponent = ({ players, cardResult }) => {
    const playerObj = organizeCards(players, cardResult);

    return (
      <>
        {Object.keys(playerObj).map((suitTitle) =>
          renderCardContainer(suitTitle, playerObj[suitTitle])
        )}
      </>
    );
  };

  const renderResult = (result) => {
    const cardResult = result.cards.split(",");
    const gtype = casinoResultList?.game_code;
    switch (true) {
      case gtype === "aaa" || gtype === "lucky7" || gtype === "lucky7eu":
        return (
          <div className="card-container">
            <img src={getCardImage(result.cards)} alt="card" />
          </div>
        );

      case gtype === "teen20" || gtype === "teen":
        return (
          <ReusableCardComponent
            players={{
              "player A": [],
              "player B": [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "ab20" || gtype === "abj":
        return (
          <ReusableCardComponent
            players={{
              Andar: [],
              Bahar: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "card32" || gtype === "card32eu":
        return (
          <ReusableCardComponent
            players={{
              "player 8": [],
              "player 9": [],
              "player 10": [],
              "player 11": [],
            }}
            cardResult={cardResult}
          />
        );

      case gtype === "dt20" || gtype === "dt202":
        return (
          <ReusableCardComponent
            players={{
              Dragon: [],
              Tiger: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "teen9" || gtype === "dtl20":
        return (
          <ReusableCardComponent
            players={{
              Dragon: [],
              Tiger: [],
              Lion: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "queen":
        return (
          <div className="card-container">
            {cardResult.map((card) => (
              <img key={card} src={getCardImage(card)} alt="card" />
            ))}
          </div>
        );
      case gtype === "baccarat2" || gtype === "baccarat":
        return (
          <ReusableCardComponent
            players={{
              Player: [],
              Banker: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "race20":
        return (
          <ReusableCardComponent
            players={{
              Spade: [],
              Heart: [],
              Diamond: [],
              Club: [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "poker20":
        return (
          <ReusableCardComponent
            players={{
              "Player A": [],
              "Player B": [],
              "Board": [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "poker6":
        return (
          <ReusableCardComponent
            players={{
              "Player 1": [],
              "Player 2": [],
              "Player 3": [],
              "Player 4": [],
              "Player 5": [],
              "Player 6": [],
              "Board": [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "teen8":
        return (
          <ReusableCardComponent
            players={{
              "Player 1": [],
              "Player 2": [],
              "Player 3": [],
              "Player 4": [],
              "Player 5": [],
              "Player 6": [],
              "Player 7": [],
              "Player 8": [],
              "Dealer": [],
            }}
            cardResult={cardResult}
          />
        );
      default:
        return (
          <div className="card-container">
            {cardResult.map((card) => (
              <img key={card} src={getCardImage(card)} alt="card" />
            ))}
          </div>
        );
    }
  };

  return (
    <div>
      <InnerPageHeader
        pageTitle="Casino Game List"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Games List", url: "/game" },
        ]}
        currenPageTitle={matchData.event_name}
      />

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h1>Agent Match Dashboard</h1>
              </div>
              <div className="ibox-content table-responsive">
                <p style={{ textAlign: "center" }}>
                  <a
                    href={`/bet_slips/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/bet_slips/${id}`,
                        state: { matchData, bookmaker_type: 4 },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Bet Slip
                    </button>
                  </a>
                  <a
                    href={`/company_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      // history.push(`/company_report/${id}`);
                      history.push({
                        pathname: `/company_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Company Report
                    </button>
                  </a>

                  <a
                    href={`/client_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      // history.push("/client_report");
                      history.push({
                        pathname: `/client_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Client Report
                    </button>
                  </a>

                  <a
                    href={`/collection_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      // history.push("/collection_report");
                      history.push({
                        pathname: `/collection_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Collection Report
                    </button>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {casinoResultList?.casino_result?.map((result, ind) => (
        <div className="result-container" key={ind + "text123"}>
          <div className="round-id">
            {result?.mid && <p>Round Id: {result?.mid}</p>}
          </div>
          <div className="card-container">
            <div className="card-container-box">
              {renderResult(result)}
              {result?.desc && (
                <p className="result-text">
                  <b>Result:</b> {result?.desc}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
