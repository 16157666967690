import React from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { staffUserCreate } from "../../_services/staff.services"
import { toast } from "react-toastify";

export default function Add(props) {

    const history = useHistory();

    const [userName, setUserName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')

    const onSubmit = (e) => {
        e.preventDefault();

        if (userName === "" || firstName === "" || lastName === "" || password === "") {
            toast.error(
                <div>
                  <i
                    className="fa fa-times"
                    style={{ fontWeight: "bold", marginRight: "1rem" }}
                    aria-hidden="true"
                  ></i>
                  <span>All filds required</span>
                </div>,
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            // toast.error("All filds required");
        } else {
            let sendObj = {}
            sendObj.username = userName;
            sendObj.password = password;
            sendObj.first_name = firstName;
            sendObj.last_name = lastName;

            staffUserCreate(sendObj)
                .then(() => {
                    history.push("/staff_list")
                }).catch(() => {
                    toast.error(
                        <div>
                          <i
                            className="fa fa-times"
                            style={{ fontWeight: "bold", marginRight: "1rem" }}
                            aria-hidden="true"
                          ></i>
                          <span>The username field must contain a unique value</span>
                        </div>,
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: true,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        }
                      );
                    // toast.error(" The username field must contain a unique value");
                })
        }
    }


    return (
        <>
            
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2> Add Staff </h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/staff_list">Staff List</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Add Staff</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <form autoComplete="off">
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">User Name</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="userName"
                                                        className="form-control"
                                                        placeholder="Enter User Name"
                                                        value={userName}
                                                        autoComplete="off"
                                                        onChange={(e) => setUserName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />


                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">First Name</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        className="form-control"
                                                        placeholder="Enter First Name"
                                                        value={firstName}
                                                        autoComplete="off"
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label"> Last Name </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={lastName}
                                                        autoComplete="off"
                                                        name="lastName"
                                                        placeholder="Enter Last Name"
                                                        onChange={(e) => setLastName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />



                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Password</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        value={password}
                                                        autoComplete="off"
                                                        name="password"
                                                        placeholder=" Enter password "
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                        </form>
                                        <div className="form-group row">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <Link
                                                    className="btn btn-white mr-3"
                                                    type="button"
                                                    style={{ marginRight: "1rem" }}
                                                    to="./staff_list"
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) => onSubmit(e)}
                                                >
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

