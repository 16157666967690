import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { getSettings, updateSettings } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import { delayList, soccerList, stakeList, tennisList } from "components/layouts/Helpers";

function GlobalSettingsComponent() {
  const [isLoading, updateLoading] = useState(false);

  const [globalSetting, updateGlobalSetting] = useState("");

  useEffect(() => {
    getSetting();
  }, []);

  function getSetting() {
    getSettings()
      .then((response) => {
        if (response && response.data) {
          const settingData = response.data.data;
          updateGlobalSetting(settingData);
        }
      })
      .catch((error) => { });
  }

  function submitGlobalSetting() {
    const checkStack = stakeList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    const checkDelay = delayList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    const checkTennis = tennisList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    const checkSoccer = soccerList.filter(
      (item) =>
        globalSetting[item?.name] === "" || globalSetting[item?.name] === null
    );
    if (checkStack.length > 0) {
      toast.error(checkStack[0].label + " require");
      return;
    }
    if (checkDelay.length > 0) {
      toast.error(checkDelay[0]?.label + " require");
      return;
    }
    if (checkTennis.length > 0) {
      toast.error(checkTennis[0]?.label + " require");
      return;
    }
    if (checkSoccer.length > 0) {
      toast.error(checkSoccer[0]?.label + " require");
      return;
    }
    if (!checkStack.length > 0 && !checkDelay.length > 0 && !checkTennis.length > 0 && !checkSoccer.length) {
      updateLoading(true);
      const data = {
        ...globalSetting,
        setting_id: 1,
      };
      updateSettings(data)
        .then((response) => {
          if (response && response.data) {
            toast("Tournament Setting updated");
            setTimeout(() => {
              updateLoading(false);
            }, 2000);
          }
        })
        .catch((error) => {
          updateLoading(false);
        });
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    submitGlobalSetting();
  }

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    updateGlobalSetting((s) => ({ ...s, [name]: Number(value) }));
  };

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Tournament Settings</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">
              <strong>Tournament Settings</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Tournament Stake Settings</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  {stakeList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="ibox-title">
                  <h5>Tournament Delay Settings</h5>
                </div>
                <div className="row">
                  {delayList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="ibox-title">
                  <h5>Tennis Stack and Delay Settings</h5>
                </div>
                <div className="row">
                  {tennisList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="ibox-title">
                  <h5>Soccer Stack and Delay Settings</h5>
                </div>
                <div className="row">
                  {soccerList.map((item) => (
                    <div className="col-sm-6" key={item?.label}>
                      <div className="form-group">
                        <label className="">{item?.label}</label>
                        <input
                          name={item?.name}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          value={globalSetting[item?.name]}
                          onChange={(e) => onChangeValue(e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="form-group row">
                  <div className="d-flex justify-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => !isLoading && onSubmit(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GlobalSettingsComponent;
