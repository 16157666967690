import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import { getColorsAPI, profileDetails } from "_services/userprofile.services";
import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  matkalockUnlock,
} from "_services";
import { subcompanyConstants } from "_constants";
import ColorModal from "components/common/ColorModal/ColorModal";
import 'components/common/ColorModal/colorModal.css'
const { themeColors } = subcompanyConstants

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        username: "",
        first_name: "",
        last_name: "",
        my_share: 0.0,
        my_agent_share: 0.0,
        fixed_limit: 0.0,
        match_commission: 0.0,
        session_commission: 0.0,
        matka_commission: 0.0,
        other_commission: 0.0,
        mobile_app_share: 0.0,
        role: "",
        theme: {}
      },
      profileData: "",
      userData: "",
      toggleColorModal: false,
      colorList: [],
      selectedColor: {},
      pickedColor: {}
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const { id } =
      this.props.match && this.props.match.params
        ? this.props.match.params
        : {};
    this.getColorList()

    if (id) {
      this.fetchUserDetails();
    }

    if (localStorage.getItem("token")) {
      profileDetails()
        .then((response) => {
          if (response && response.data && response.data.data) {
            localStorage.setItem("profile", JSON.stringify(response.data.data));
            let profile = response.data.data;
            this.setState({ profileData: profile });
            this.setState((state) => ({
              formObj: {
                ...state.formObj,
                role: this.props.role,
              },
            }));
          }
        })
        .catch((error) => {
          let profile = JSON.parse(localStorage.getItem("profile"));
          this.setState({ profileData: profile });
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              role: this.props.role,
            },
          }));
        });
    }

    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          mobile_app_share: 100,
        },
      }));
    }
  }

  fetchUserDetails = () => {
    const { id } = this.props.match?.params;
    if (id) {
      let sendData = {};
      sendData.user_guid = id;
      profileDetails(sendData)
        .then((response) => {
          if (response?.data?.data) {
            this.setState({ userData: response?.data?.data });
          } else {
            this.setState({ userData: null });
          }
        })
        .catch((error) => { });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.userData !== this.state.userData &&
      this.state.userData &&
      this.props.isEdit
    ) {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          username: this.state.userData?.username,
          first_name: this.state.userData?.first_name,
          last_name: this.state.userData?.last_name,
          my_share: this.state.userData?.my_share,
          my_agent_share: this.state.userData?.company_share,
          fixed_limit: this.state.userData?.fixed_limit,
          match_commission: this.state.userData?.match_commission,
          session_commission: this.state.userData?.session_commission,
          matka_commission: this.state.userData?.matka_commission,
          other_commission: this.state.userData?.other_commission,
          mobile_app_share: this.state.userData?.mobile_app_charges,
        },
      }));
    }
  }

  setMyShareSubCompanyValue = (val = 0) => {
    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          my_share: 100 - val,
        },
      }));
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      if (this.props.isEdit) {
        const { id } =
          this.props.match && this.props.match.params
            ? this.props.match.params
            : {};
        let obj = {};
        obj.first_name = formObj.first_name;
        obj.last_name = formObj.last_name;
        obj.user_guid = id;
        obj.my_agent_share = formObj.my_agent_share;
        obj.match_commission = formObj.match_commission;
        obj.session_commission = formObj.session_commission;
        obj.matka_commission = formObj.matka_commission;
        obj.other_commission = formObj.other_commission;
        obj.theme = this.state.selectedColor
        this.props.onSubmitFunc(obj);

      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();

    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  checkLimit = () => {
    try {
      let userRole = localStorage.getItem("role") || null;
      let { profileData } = this.state;
      return (
        userRole &&
        userRole !== "1" &&
        profileData &&
        profileData.remaining_balance &&
        profileData.remaining_balance < 0
      );
    } catch (err) {
      return false;
    }
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response) {
          this.fetchUserDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          this.fetchUserDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchUserDetails();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) { }
    }
  }

  lockUnlockMatka(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_matka = user.lock_matka == "0" ? 1 : 0;
      try {
        matkalockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchUserDetails();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  getColorList = () => {
    getColorsAPI().then((res) => {
      if (res.status === 200) {
        const { data = [] } = res?.data
        this.setState({ colorList: data, selectedColor: data.filter((item) => +item.is_default === 1)[0] })
      }
    })
  }

  render() {
    let userRole = localStorage.getItem("role") || null;
    let {
      redirect,
      profileData,
      userData,
      toggleColorModal,
      colorList,
      selectedColor,
      pickedColor
    } = this.state;
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
      updateData,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: breadcrumb, url: navigationUrl },
          ]}
          currenPageTitle={action}
        />

        

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{heading ? heading : "Create"}</h5>
                </div>

                {
                  <div className="ibox-content">
                    <form onSubmit={this.onSubmit} className="form-horizontal">
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          User name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="username"
                            value={this.state.formObj.username}
                            onChange={(e) => this.handleInput(e)}
                            disabled
                          />
                          <em>
                            {this.validator.message(
                              "username",
                              this.state.formObj.username,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          First name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            id="first_name"
                            value={this.state.formObj.first_name}
                            onChange={(e) => this.handleInput(e)}
                          />

                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Last name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            id="last_name"
                            value={this.state.formObj.last_name}
                            onChange={(e) => this.handleInput(e)}
                          />
                          <em>
                            {this.validator.message(
                              "last_name",
                              this.state.formObj.last_name,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Company Match Earning Share (My share)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="my_agent_share"
                            id="my_agent_share_subcompany"
                            value={this.state.formObj.my_agent_share}
                            // value={profileData && profileData.company_share}
                            onChange={(e) => {
                              this.handleInput(e);
                              this.setMyShareSubCompanyValue(e.target.value);
                            }}
                            max={999}
                          />
                          <em>
                            {this.validator.message(
                              "my_agent_share",
                              this.state.formObj.my_agent_share,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Company Usage Charges Share
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="mobile_app_share"
                            id="mobile_app_share_subcompany"
                            value={this.state.formObj.mobile_app_share}
                            onChange={(e) => this.handleInput(e)}
                            disabled
                          />
                          <em>
                            {this.validator.message(
                              "mobile_app_share",
                              this.state.formObj.mobile_app_share,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Fix Limit
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="fixed_limit"
                            id="fixed_limit"
                            value={this.state.formObj.fixed_limit}
                            onChange={(e) => this.handleInput(e)}
                            max={profileData && profileData.remaining_balance}
                            disabled={this.props.isEdit}
                          />
                          <div
                            className={
                              this.checkLimit()
                                ? "agent_share_error danger-red"
                                : "agent_share_error"
                            }
                          >
                            <b>Note</b> :Agent Fix Limit set below or equal to{" "}
                            <b>
                              {profileData && profileData.remaining_balance}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "fixed_limit",
                              this.state.formObj.fixed_limit,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Match Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="match_commission"
                            id="match_commission"
                            value={this.state.formObj.match_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Agent Match Commission set below or
                            equal to{" "}
                            <b>
                              {userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "match_commission",
                              this.state.formObj.match_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Session Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="session_commission"
                            id="session_commission"
                            value={this.state.formObj.session_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Agent Session Commission set below or
                            equal to{" "}
                            <b>
                              {userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "session_commission",
                              this.state.formObj.session_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Matka Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="matka_commission"
                            id="matka_commission"
                            value={this.state.formObj.matka_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              userRole && userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Matka Commission set below or
                            equal to{" "}
                            <b>
                              {userRole && userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "matka_commission",
                              this.state.formObj.matka_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Other Commission ( Single Digit, Even Odd )
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="other_commission"
                            id="other_commission"
                            value={this.state.formObj.other_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Other Commission set below or
                            equal to{" "}
                            <b>
                              {userRole && userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "other_commission",
                              this.state.formObj.other_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>

                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          User Active/Inactive
                        </label>
                        <div className="col-sm-9 toggle-btn">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={userData && userData.status == "1"}
                              onChange={(e) =>
                                this.statusChange(e, userData, false)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Bet Lock/Unlock
                        </label>
                        <div className="col-sm-9 toggle-btn">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={userData && userData.lock_betting == "0"}
                              onChange={(e) =>
                                this.lockUnlockBet(e, userData, false)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Casino Lock/UnLock
                        </label>
                        <div className="col-sm-9 toggle-btn">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                userData && userData.lock_casino_bet == "0"
                              }
                              onChange={(e) =>
                                this.lockUnlockCasino(e, userData, false)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      {parentType === "subcompany" &&
                        <>
                          <>
                            <div className="form-group">
                              <label className="col-sm-3 control-label">
                                Colour Theme
                              </label>
                              <div className="col-sm-9 d-flex align-center">
                                <div className="d-flex flex-center selected_color">
                                  <div style={{ backgroundColor: selectedColor?.color_code?.primary }}>
                                  </div>
                                  <div style={{ backgroundColor: selectedColor?.color_code?.secondary }}>
                                  </div>
                                </div>
                                <div className="pointer" onClick={() => this.setState({ toggleColorModal: true })}>
                                  Choose Colour
                                </div>
                              </div>
                            </div>
                            <div className="hr-line-dashed"></div>
                          </>
                        </>
                      }
                      <div className="form-group">
                        <div className="col-sm-4 col-sm-offset-2">
                          <button
                            className="btn btn-white mr-3"
                            type="button"
                            style={{ marginRight: "1rem" }}
                            onClick={() =>
                              this.props.history.push(navigationUrl)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={this.checkLimit()}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
          <ColorModal
            colorList={colorList}
            isShow={toggleColorModal}
            onCancle={() => this.setState({ toggleColorModal: false })}
            onSubmit={() => this.setState({ selectedColor: pickedColor, toggleColorModal: false })}
            selectColor={(color) => this.setState({ pickedColor: color })}
            pickedColor={pickedColor}
          />
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Edit);
