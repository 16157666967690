import React, { Component, useEffect, useState } from "react";

import InnerPageHeader from "components/common/InnerPageHeader";
import { casinoProviders, updateUserCasinoStatus } from "_services/casino.services";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Main from "components/layouts/Main";

const CasinoSetting = () => {
    const { user_id } = useParams();
    const [casinoList, setCasinoList] = useState([])

    useEffect(() => {
        getCasinoList()
    }, [])

    const getCasinoList = () => {

        let sendObj = {};
        sendObj.user_id = user_id ? user_id : 1
        casinoProviders(sendObj)
            .then((response) => {
                if (response && response?.data?.data) {
                    setCasinoList(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (item) => {
        let sendObj = {};
        sendObj.user_id = user_id ? user_id : 1
        sendObj.provider_id = item.provider_id
        sendObj.is_active = item.is_active == 1 ? 0 : 1
        updateUserCasinoStatus(sendObj)
            .then((response) => {
                console.log('responise', response)
                if (response && response.data && response.data.message) {
                    toast.success(response.data.message)
                    getCasinoList()
                }
            })
            .catch((error) => {
                toast.error("something went wrong.")
                // console.log(error);
            });
    };

    return (
        <Main>
            <InnerPageHeader
                pageTitle="Casino Setting"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                    { title: "Casino Setting", url: "/casino-setting" },
                ]}
                currenPageTitle="Casino Setting"
            />
            <div
                className="wrapper wrapper-content animated fadeInRight"
                style={{ paddingBottom: "0px" }}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 style={{ fontWeight: 700 }}></h5>
                                <div className="ibox-tools">

                                </div>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        className="dataTables_wrapper form-inline dt-bootstrap"
                                    >
                                        <table className="table table-striped table-bordered table-hover dataTables-example">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '70%' }}>Casino</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {casinoList.map((item, ind) => {
                                                    return (
                                                        <tr key={ind} className="gradeX">
                                                            <td>{item.name}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <input type="checkbox"
                                                                    checked={item.is_active == 1 ? true : false}
                                                                    name="status" id=""
                                                                    onChange={(e) => handleChange(item)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        <div className="row" style={{ margin: "15px 0px" }}>
                                            <div className="clearfix visible-xs"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default CasinoSetting

