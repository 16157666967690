import Main from "components/layouts/Main";
import React from "react";

class RulesComponent extends React.Component {
  state = {
    language: "hindi",
  };
  render() {
    return (
      <Main>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins rules-main-container px-2 ">
                {/* <div className="lang-btn-container border-bottom">
                  <div className="col-container mx-0 px-2 row d-flex">
                    <div className="btn-filter mt-3 mb-3">
                      <div>
                        <li
                          className={
                            this.state.language === "hindi"
                              ? "active mt-1"
                              : "mt-1"
                          }
                          onClick={() => this.setState({ language: "hindi" })}
                        >
                          हिंदी
                        </li>
                      </div>
                    </div>
                    <div className="btn-filter mt-3 mb-3">
                      <div>
                        <li
                          className={
                            this.state.language === "english"
                              ? "active mt-1"
                              : "mt-1"
                          }
                          onClick={() => this.setState({ language: "english" })}
                        >
                          English
                        </li>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {this.state.language === "hindi" ? (
                  <HindiRuleComponent />
                ) : (
                  <EnglishRuleComponent />
                )} */}
                <div className="ibox-content">
                  <div className="table-responsive">
                    <MatkaInfo />
                    <hr />
                    <FastMatkaInfo />
                    <hr />
                    <table className="table table-striped table-bordered table-hover dataTables-example">
                      <thead>
                        <tr>
                          <th colSpan={2}>Game bazar me diye hua time se 10 minutes pehle bet band hongi</th>
                        </tr>
                      </thead>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

const HindiRuleComponent = () => {
  return (
    <>
      <div>
        <ul className="hindi-note-text">
          <li>
            <b></b>
            <p style={{ textAlign: "center" }}>
              <span>
                {/* &#127951; */}
                <span role="img" aria-label="emoji name">
                  {" "}
                  🏏{" "}
                </span>{" "}
                !!  TOP2020 में आपका स्वागत है !!{" "}
                <span role="img" aria-label="emoji name">
                  {" "}
                  🏏{" "}
                </span>
                <br />
                <br />
                नोट :- TOP के सभी ID मैं रूले, इनसाइड आउटसाइड(अंदर-बाहर), और तीन
                पत्ती ये सभी लाइव गेम इन-प्ले हो गए हे| आपको गेम के आवश्यकता हो
                तो आप अपने एजेंट से संपर्क करे| TOPGROUP
              </span>
            </p>
          </li>
        </ul>
        <br />
        <ol className="hindi-rules-list">
          <li>
            कृपया TOP2020 के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने
            अनुसार समझ लें ।
          </li>
          <li>
            1 से 2 मिनट और 2 से 4 पैसे के अंतर में खाई/लगाई पर आपके प्रॉफिट के
            सौदे काट दिए जाएगे| इसके लिए क्लाइंट का कोई भी वाद विवाद मान्य नहीं
            होगा और पैसा आपको देना होगा|{" "}
          </li>
          <li>
            सभी क्लाइंट को सुचित किया जाता है की कृपया सामान्य पासवर्ड ना रखें
            उदा : 1234,12345,123456..etc
          </li>
          <li> लॉग इन करने के बाद अपना पासवर्ड बदल लें |</li>
          <li>प्रत्येक गेम के लिए 10.0/- कॉइन्स चार्ज रहेगा|</li>
          <li>
            यदि आप मैच या सेशन का एक भी सौदा नहीं करते हो, ऐसे में आपसे 10.0/-
            कॉइन्स का चार्ज नहीं लिया जायेगा |
          </li>
          <li>
            खेल रद्द या टाई होने पर सभी सौदे रद्द कर दिए जाएंगे और लेनदेन सेशन
            जो पूरा हो गया है उस पर किया जाएगा।
          </li>
          <li>
            मैच का सौदा कम से कम 100.0 और अधिकतम 1500000.0 है और सेशन का सौदा कम
            से कम 100.0 और अधिकतम 500000.0 है।
          </li>
          <li>
            मैच के दौरान भाव को देख और समझ कर ही सौदा करें | किये गए किसी भी
            सौदे को हटाया या बदला नहीं जायेगा | सभी सौदे के लिए आप स्वयं
            जिम्मेवार हैं |
          </li>
          <li>यहाँ सभी सौदे लेजर से मान्य किये जायेंगे |</li>
          <li>इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी |</li>
          <li>
            मैच शुरू होने के पहले अगर कम ओवर का मैच हो जाता है तो एडवांस फैंसी,
            सेशन के सौदे हटा दिए जाएंगे |
          </li>
        </ol>

        <ul className="hindi-note-text">
          <li>
            नोट : सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर
            केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का
            वाद-विवाद मान्य नहीं होगा |
          </li>
        </ul>
      </div>
    </>
  );
};

const EnglishRuleComponent = () => {
  return (
    <>
      <div>
        <ul className="hindi-note-text">
          <li>
            <b></b>
            <p style={{ textAlign: "center" }}>
              🏏 !! Welcome To  TOP2020 !! 🏏<br></br>
              Note :- TOP Ke Sabhi ID Me Roulette, Inside Outside(Andar Bahar),
              &amp; Teen Patti Yee sabhi Live Game inplay Ho gay hai aapko Game
              ke Requirement ho tho aap apne Agent se Contact kare. TOPGROUP
            </p>
          </li>
        </ul>
        <br />
        <ol className="hindi-rules-list" style={{ display: "block" }}>
          <li>
            Please give few minutes to understand rules of  TOP2020 here, as best
            as you can.
          </li>
          <li>
            Your profit trades will be deducted on the gap between 1 to 2
            minutes and 2 to 4 paise. For this, any dispute of the client will
            not be valid and you will have to pay the money.
          </li>
          <li>
            All clients are advised that please do not keep common password eg
            :1234,12345,123456..etc
          </li>
          <li>Change your password after you log in.</li>
          <li>For every match 10.0/- coins will be charged.</li>
          <li>
            10.0/- coins will be charged if user will play any Market bet or
            Session bet in a match.
          </li>
          <li>
            If game cancelled or tie then all the deals will be cancelled and
            the transactions will be done on session which is completed.
          </li>
          <li>
            The deal of the match is at least 100.0 and maximum 1500000.0 and
            the deal of session is at least 100.0 and maximun 500000.0.
          </li>
          <li>
            During the match, please bet only after confirming the deal. Once
            the deal is confirmed, it cannot be changed or removed.
            Responsibility of every deal is yours.
          </li>
          <li>All transactions will be validated from ledger only.</li>
          <li>
            It'll be user's responsibility for internet connection problem.
          </li>
          <li>
            Match start hone ke phele agar kam over ka match ho jata hai toi
            advance fancy, session ke saude hata diye jaenge.
          </li>
        </ol>
        <ul className="hindi-note-text">
          <li>
            Note: If some kind of breakdown occurs in server or website, only
            successful bets will be accepted. In such situation, any kind of
            debate will be invalid.
          </li>
        </ul>
      </div>
    </>
  );
};

const MatkaInfo = () => {
  return (
    <table className="table table-striped table-bordered table-hover dataTables-example">
      <thead>
        <tr>
          <th colSpan={2}>Matka Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Single digit</td>
          <td>100-950</td>
        </tr>
        <tr>
          <td>Jodi</td>
          <td>100-9500</td>
        </tr>
        <tr>
          <td>Single patti </td>
          <td>100-15000</td>
        </tr>
        <tr>
          <td>Double patti</td>
          <td>100-30000</td>
        </tr>
        <tr>
          <td>Tripal patti</td>
          <td>100-80000</td>
        </tr>
      </tbody>
    </table>
  )
}
const FastMatkaInfo = () => {
  return (
    <table className="table table-striped table-bordered table-hover dataTables-example">
      <thead>
        <tr>
          <th colSpan={2}>Fast Matka Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Single digit</td>
          <td>100-975</td>
        </tr>
        <tr>
          <td>Single patti </td>
          <td>100-16000</td>
        </tr>
        <tr>
          <td>Double patti</td>
          <td>100-32000</td>
        </tr>
        <tr>
          <td>Trail patti </td>
          <td>100-90000</td>
        </tr>

      </tbody>
    </table>
  )
}

export default RulesComponent;
