import DashboardCard from "components/common/DashboardCard/DashboardCard";
import SectionTitle from "components/common/SectionTitle";
import InnerPageHeader from "../common/InnerPageHeader";
import React, { useState, useEffect, Component } from "react";
import "./Dashboard.css";
import { userProfileRequest } from "_actions/userprofile/userprofile.actions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    userdata: [],
    role: 0,
    roleName: Array(),

  };
  // Roles
  // Role 1 - Company 
  // Role 2 - Sub Company 
  // Role 3 - Master
  // Role 4 - Super Stockists
  // Role 5 - Stockists
  // Role 6 - Agent 
  componentDidMount() {

    this.state.roleName[0] = "Company";
    this.state.roleName[1] = "Company";
    this.state.roleName[2] = "Sub Company ";
    this.state.roleName[3] = "Master";
    this.state.roleName[4] = "Super Stockists";
    this.state.roleName[5] = "Stockists";
    this.state.roleName[6] = "Agent";
    this.state.roleName[7] = "Client";

    let role = localStorage.getItem("role") || null;
    this.state.role = role;

    let sendObj = {
      role: role,
    };
    this.props.dispatch(
      userProfileRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            userdata: res.data,

          });
        }
      })
    );
  }

  render() {
    const { userdata, role } = this.state;
    return (
      <>
        <InnerPageHeader
          pageTitle="Live Report Matches"
          breadcrumbs={[
            { title: "Dashboard", url: "" },
          ]}
          currenPageTitle={userdata?.username ? `${userdata.username} (${userdata.first_name})` : ''}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="DashBoardCardContainer">

            <DashboardCard title={"MY USERNAME"} text={userdata.username} lastName={"" + userdata.name + ""} />
            <DashboardCard title={"MY LEVEL"} text={this.state.roleName[this.state.role]} />
            {userdata.role != 1 && <DashboardCard title={"MY FIX LIMIT"} text={userdata.fixed_limit ? (userdata.fixed_limit) : ("")} />}

            <DashboardCard
              title={"Company Contact"}
              text={this.state.roleName[(userdata?.parent_role)]}
            />

            {userdata.role == 2 ?
              <DashboardCard title={"Maximum My Share"} text={100 - userdata.company_share + "%"} />
              : <DashboardCard title={"Maximum My Share"} text={userdata.my_agent_share ? (userdata.my_agent_share + "%") : ("")} />}

            {userdata.role == 2 ?
              <DashboardCard title={"Minimum Company Share"} text={userdata.company_share + "%"} />
              : <DashboardCard title={"Minimum Company Share"} text={userdata.my_share ? (100 - userdata.my_agent_share + "%") : ("")} />}

            <DashboardCard title={"Match Commission"} text={userdata.match_commission ? (userdata.match_commission + "." + 0) : ("")} />
            <DashboardCard title={"Session Commission"} text={userdata.session_commission ? (userdata.session_commission + "." + 0) : ("")} />

            {/* {userdata.role!=2? 
            <DashboardCard title={"My Agent Share"} text={userdata.my_agent_share ? (userdata.my_agent_share + "%") : ("")} />
            :''} */}

            {/*userdata.role!=2? 
            <DashboardCard title={"Company Game Share"} text={userdata.game_share ? ((100- userdata.agent_game_share ) + "%") : ("")} />
            :''*/}

            {/*userdata.role!=2? 
            <DashboardCard title={"My Game Share"} text={userdata.agent_game_share ? (userdata.agent_game_share + "%") : ("")} />
            :''*/}

            {/*userdata.role!=2? 
            <DashboardCard title={"Company Matka Share"} text={userdata.matka_share ? ((100- userdata.matka_agent_share) + "%") : ("")} />
            :''*/}

            {/*userdata.role!=2? 
            <DashboardCard title={"My Matka Share"} text={userdata.matka_agent_share ? (userdata.matka_agent_share + "%") : ("")} />
            :''*/}
          </div>
        </div>
      </>
    );
  }
}
export default Dashboard;
