import _ from 'lodash';

const DevHost     = ["localhost","betlagao.com", "www.betlagao.com","user.betlagao.com","admin.betlagao.com"];
const LiveHost    = ["top1010.pro","top1010.in","www.top1010.pro","www.top1010.in"];
const StagHost     = ["top2020.pro","top2020.in","www.top2020.pro","www.top2020.in"];

const hostname = window.location.hostname;

const live = {
  baseURL: "https://adminapi.top1010.pro/api",
  socketURL: "https://node.top1010.pro"
};

const dev = {
  baseURL: "https://adminapi.betlagao.com/api",
  socketURL: "https://node.betlagao.com"
};

const stag = {
  baseURL: "https://adminapi.top2020.pro/api",
  socketURL: "https://node.top2020.pro"
};


export const AppConfig =  _.includes(LiveHost, hostname)? live: (_.includes(StagHost, hostname)? stag: dev);

// export const AppConfig =  _.includes(LiveHost, hostname) ? dev: live;
